<template>
	<div class="scorebox">
		<div v-if="examData.stex_ispass == 1">
			<div class="scoreTit">恭喜您，考试通过！</div>
			<img class="scoreImg" src="@/views/images/success_icon.png" />
		</div>
		<div v-else>
			<div class="scoreTit">很遗憾，考试未通过！</div>
			<img class="scoreImg" src="@/views/images/fail_icon.png" />
		</div>
		<div class="scoreInfo">
			<div class="scoreInfo_item">
				<p class="scoreInfo_item_num">{{examData.stex_score}}</p>
				<p>得分</p>
			</div>
			<div class="scoreInfo_item">
				<p class="scoreInfo_item_num">{{totalSeconds(examData.stex_answertime)}}</p>
				<p>用时</p>
			</div>
			<div class="scoreInfo_item">
				<p class="scoreInfo_item_num">{{examData.stex_total_num + examData.stex_total_error}}/{{examData.stex_total}}</p>
				<p>答题情况</p>
			</div>
		</div>
		<div class="scoreBtnList">
			<div class="scoreBtn" @click="closeScore" v-if="examData.stex_ispass == 1">结束考试</div>
			<div class="scoreBtn" @click="onceMore" v-else>重新考试</div>
			<div class="scoreBtn" @click="answerSheet">查看答卷</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			examData:{
				type: Object
			}
		},
		methods:{
			//结束考试
			closeScore(){
				this.$emit('close-score');
			},
			//重新考试
			onceMore(){
				this.$emit('once-more');
			},
			//查看答卷
			answerSheet(){
				this.$emit('answer-sheet');
			},
			//显示用时
			totalSeconds(val){
				//取模（余数）
				var modulo = val % (60 * 60 * 24);
				//小时数
				var hours = Math.floor(modulo / (60 * 60));
				modulo = modulo % (60 * 60);
				//分钟
				var minutes = Math.floor(modulo / 60);
				//秒
				var seconds = modulo % 60;
				hours = hours.toString().length == 1 ? '0' + hours : hours;
				minutes = minutes.toString().length == 1 ? '0' + minutes : minutes;
				seconds = seconds.toString().length == 1 ? '0' + seconds : seconds;
				//输出到页面
				return  hours + ":" + minutes + ":" + seconds;
			},
		}
	}
</script>

<style scoped="scoped">
	.scorebox{
		width: 350px;
		height: 370px;
		background-color: #FFF;
		text-align: center;
	}
	.scoreTit{
		font-size: 16px;
		color: #333;
		line-height: 68px;
	}
	.scoreImg{
		width: 70px;
		height: 70px;
	}
	.scoreInfo::after{
		content: "";
		display: block;
		clear: both;
	}
	.scoreInfo_item{
		width: 33.33%;
		float: left;
		font-size: 15px;
		color: #333;
		font-weight: lighter;
		line-height: 30px;
		padding: 35px 0;
	}
	.scoreBtn{
		display: inline-block;
		width: 120px;
		height: 44px;
		border-radius: 22px;
		line-height: 44px;
		color: #fff;
		background-color: #4C6DF1;
		font-size: 14px;
		margin: 0 10px;
		cursor: pointer;
	} 
	.scoreBtn:hover{
		opacity: 0.8;
	}
	.scoreInfo_item_num{
		font-size: 18px;
		font-weight: bold;
	}
</style>
