<template>
	<div class="answer_box">
		<div class="answerHeader">模拟考试（课程名称：{{couData.cou_name}}）答卷</div>
		<div class="answerInfo">
			<div class="answerLeft">
				<div class="answerScore_box">
					<p class="answerScore">{{Number(studentExam.stex_score)}}</p>
					<p class="answerTitle">得分</p>
				</div>
			</div>
			<div class="answerRight">
				<div>
					<p>总用时</p>
					<p>答对（题）</p>
					<p>答错（题）</p>
					<p>未答（题）</p>
				</div>
				<div>
					<p class="totalTime">{{totalSeconds(studentExam.stex_answertime)}}</p>
					<p class="dadui">{{studentExam.stex_total_num}}</p>
					<p class="dacuo">{{studentExam.stex_total_error}}</p>
					<p class="weida">{{studentExam.stex_total_empty}}</p>
				</div>
			</div>
		</div>
		<div class="answerSubject" ref="answerSubject">
			<div class="answerSubject_item" v-for="(item,index) in answerList" :key="index" :ref="item.que_id">
				<div class="subjectHeader">
					<div class="subjectType">{{item.type_text}}</div>
					<div class="subjectIndex">
						<span>第</span>
						<span class="indexColor">{{index + 1}}</span>
						<span>/{{answerList.length}}</span>
					</div>
					<div class="subjectTitle">{{item.name_text}}</div>
				</div>
				<div class="optionList">
					<ul v-if="item.type_text == '多选题'">
						<li v-if="item.que_optiona" class="multipleItem" :class="multiplebindClass('A',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>A</span>
							<span class="optiontit">{{item.que_optiona}}</span>
						</li>
						<li v-if="item.que_optionb" class="multipleItem" :class="multiplebindClass('B',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>B</span>
							<span class="optiontit">{{item.que_optionb}}</span>
						</li>
						<li v-if="item.que_optionc" class="multipleItem" :class="multiplebindClass('C',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>C</span>
							<span class="optiontit">{{item.que_optionc}}</span>
						</li>
						<li v-if="item.que_optiond" class="multipleItem" :class="multiplebindClass('D',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>D</span>
							<span class="optiontit">{{item.que_optiond}}</span>
						</li>
						<li v-if="item.que_optione" class="multipleItem" :class="multiplebindClass('E',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>E</span>
							<span class="optiontit">{{item.que_optione}}</span>
						</li>
						<li v-if="item.que_optionf" class="multipleItem" :class="multiplebindClass('F',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>F</span>
							<span class="optiontit">{{item.que_optionf}}</span>
						</li>
					</ul>
					<ul v-else>
						<li v-if="item.que_optiona" class="optionItem" :class="bindClass('A',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>A</span>
							<span class="optiontit">{{item.que_optiona}}</span>
						</li>
						<li v-if="item.que_optionb" class="optionItem" :class="bindClass('B',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>B</span>
							<span class="optiontit">{{item.que_optionb}}</span>
						</li>
						<li v-if="item.que_optionc" class="optionItem" :class="bindClass('C',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>C</span>
							<span class="optiontit">{{item.que_optionc}}</span>
						</li>
						<li v-if="item.que_optiond" class="optionItem" :class="bindClass('D',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>D</span>
							<span class="optiontit">{{item.que_optiond}}</span>
						</li>
						<li v-if="item.que_optione" class="optionItem" :class="bindClass('E',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>E</span>
							<span class="optiontit">{{item.que_optione}}</span>
						</li>
						<li v-if="item.que_optionf" class="optionItem" :class="bindClass('F',item)">
							<div class="circular"><span class="csolid"></span></div>
							<span>F</span>
							<span class="optiontit">{{item.que_optionf}}</span>
						</li>
					</ul>
				</div>
				<div class="answerList">
					<div class="userAnswerPath">您的答案：{{item.exre_answer}}</span></div>
					<div class="rightAnswerPath">正确答案：{{item.que_answer}}</div>
				</div>
			</div>
		</div>
		<div class="answerNoList">
			<ul>
				<li class="answerNoItem" v-for="(item,index) in answerList" :key="index" 
				:class="[{numCorrect: item.que_answer == item.exre_answer},
				{numError: item.exre_answer && item.que_answer != item.exre_answer}]" 
				@click="clickanswerNo(item.que_id)">
					{{index + 1}}
				</li>
			</ul>
			<div class="legend">
				<p><span class="daduiLen legendIcon"></span><span class="legendText">正确</span></p>
				<p><span class="dacuoLen legendIcon"></span><span class="legendText">错误</span></p>
				<p><span class="weidaLen legendIcon"></span><span class="legendText">未答</span></p>
			</div>
			<div class="gotoList" @click="gobackList">返回列表</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	export default {
		props:{
			couData:{
				type: Object
			},
			answerList:{
				type: Array
			},
			studentExam:{
				type: Object
			}
		},
		methods:{
			//返回列表
			gobackList(){
				this.$emit('goback-list');
			},
			//显示用时
			totalSeconds(val){
				//取模（余数）
				var modulo = val % (60 * 60 * 24);
				//小时数
				var hours = Math.floor(modulo / (60 * 60));
				modulo = modulo % (60 * 60);
				//分钟
				var minutes = Math.floor(modulo / 60);
				//秒
				var seconds = modulo % 60;
				hours = hours.toString().length == 1 ? '0' + hours : hours;
				minutes = minutes.toString().length == 1 ? '0' + minutes : minutes;
				seconds = seconds.toString().length == 1 ? '0' + seconds : seconds;
				//输出到页面
				return  hours + ":" + minutes + ":" + seconds;
			},
			//答卷多选绑定
			multiplebindClass(type,item){
				if(item){
					var choseAnswer = [];
					var que_answer = item.que_answer.split(",");
					if(item.exre_answer){
						choseAnswer = item.exre_answer.split(",");
					}
					var errorArr = []
					for(var i=0;i<choseAnswer.length;i++){
						if(que_answer.indexOf(choseAnswer[i]) == -1){
							errorArr.push(choseAnswer[i])
						}
					}
					var className = "";
					if(this.PublicJs.ArrayIndexOf(type,que_answer)>-1){
						className += "correctM"
					}
					if(this.PublicJs.ArrayIndexOf(type,errorArr)>-1){
						className += "errorM"
					}
					return className
				}else{
					return ""
				}
			},
			//答卷单选绑定
			bindClass(type,item){
				if(item){
					var choseAnswer = item.exre_answer;
					var que_answer = item.que_answer;
					var errorArr = "";
					if(choseAnswer && choseAnswer!=que_answer){
						errorArr = choseAnswer
					}
					var className = "";
					if(que_answer == type){
						className += "correct"
					}
					if(errorArr == type){
						className += "error"
					}
					return className
				}else{
					return ""
				}
			},
			//点击跳转对应题目
			clickanswerNo(val){
				var that = this;
				var scrollTopVal = that.$refs[val][0].offsetTop - that.$refs.answerSubject.offsetTop
				$(this.$refs.answerSubject).animate({
					 scrollTop: scrollTopVal,
				}, 1000);
			}
		}
	}
</script>

<style scoped="scoped">
	.answer_box{
		background-color: #F5F7F9;
		padding: 10px;
		position: relative;
	}
	.answer_box::after{
		content: "";
		clear: both;
		display: block;
	}
	.answerHeader{
		width: 100%;
		height: 40px;
		background-color: #FFF;
		line-height: 40px;
		padding-left: 10px;
	}
	.answerInfo{
		width: 100%;
		height: 90px;
		margin-top: 10px;
		background-color: #FFF;
		margin-bottom: 10px;
	}
	.answerInfo::after{
		content: "";
		display: block;
		clear: both;
	}
	.answerLeft{
		width: 200px;
		height: 100%;
		float: left;
	}
	.answerScore_box{
		width: 80px;
		height: 80px;
		border-radius: 40px;
		background-color: #3487F9;
		margin: 5px auto;
		text-align: center;
		color: #FFF;
		padding-top: 13px;
	}
	.answerScore{
		font-size: 30px;
		font-weight: bold;
		line-height: 30px;
		margin-bottom: 7px;
	}
	.answerTitle{
		font-size: 14px;
		line-height: 14px;
	}
	.answerRight{
		width: 680px;
		float: left;
	}
	.answerRight div{
		width: 100%;
		height: 45px;
		line-height: 45px;
		text-align: center;
		font-size: 0;
	}
	.answerRight div:first-child{
		border-bottom: 1px solid #E2E2E2;
	}
	.answerRight div p{
		display: inline-block;
		font-size: 14px;
		width: 170px;
		border-left: 1px solid #E2E2E2;
	}
	.totalTime{
		color: #44A4F6;
		font-weight: bold;
	}
	.dadui{
		color: #5EC513;
		font-weight: bold;
	}
	.dacuo{
		color: #EA3410;
		font-weight: bold;
	}
	.weida{
		color: #C1C1C1;
		font-weight: bold;
	}
	.answerSubject{
		width: 610px;
		float: left;
		background-color: #FFF;
		height: 500px;
		overflow: auto;
	}
	.answerNoList{
		width: 260px;
		float: right;
		background-color: #FFF;
	}
	.answerNoList ul{
		padding: 10px 0 0 10px;
		overflow: auto;
		height: 226px;
	}
	.answerNoList ul::after{
		content: "";
		clear: both;
		display: block;
	}
	.answerNoItem{
		float: left;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 28px;
		cursor: pointer;
		margin: 0px 3px 6px 0px;
		box-sizing: border-box;
		border-radius: 3px;
		font-size: 12px;
		background-color: #ffffff;
		border: 1px solid #e2e2e2;
		color: #666666;
	}
	.answerNoItem.numError{
		background-color: #EA3410;
		border: 1px solid #EA3410;
		color: #FFF;
	}
	.answerNoItem.numCorrect{
		background-color: #5EC513;
		border: 1px solid #5EC513;
		color: #FFF;
	}
	.legend{
		width: 240px;
		border-top: 1px dashed #E2E2E2;
		margin: 0 auto;
		margin-top: 10px;
		padding: 10px;
	}
	.legend p{
		display: inline-block;
		font-size: 0;
		line-height: 30px;
		margin-right: 20px;
	}
	.legend p span.legendIcon{
		display: inline-block;
		width: 14px;
		height: 14px;
		border-radius: 3px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.legend p span.legendText{
		vertical-align: middle;
		font-size: 14px;
	}
	.legend p span.daduiLen{
		background-color: #5EC513;
	}
	.legend p span.dacuoLen{
		background-color: #EA3410;
	}
	.legend p span.weidaLen{
		border: 1px solid #e2e2e2;
	}
	.gotoList{
		width: 220px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		margin: 10px auto 20px;
		background-color: #4F6FF1;
		color: #FFF;
		border-radius: 5px;
		cursor: pointer;
	}
	.gotoList:hover{
		opacity: 0.8;
	}
	.answerSubject_item{
		border-bottom: 1px solid #e2e2e2;
	}
	.answerSubject_item .subjectHeader{
		padding-left: 10px;
	}
	.answerSubject_item .optionList{
		border-bottom:0;
		padding-bottom: 0;
	}
	.answerSubject_item .answerList{
		height: 40px;
		line-height: 40px;
		background-color: #F8F8F8;
		padding: 0 10px;
		border-top: 1px dashed #e2e2e2;
	}
	.answerSubject_item .answerList div{
		display: inline-block;
		margin-right: 40px;
		font-size: 12px;
	}
	.subjectHeader{
		height: 25px;
		margin-top: 20px;
	}
	.subjectHeader::after{
		content: "";
		display: block;
		clear: both;
	}
	.subjectType{
		float: left;
		height: 25px;
		padding: 0px 7px;
		background-color: #2B54A9;
		font-size: 12px;
		color: #ffffff;
		border-radius: 3px;
		line-height: 25px;
		margin-right: 10px;
	}
	.subjectIndex{
		font-size: 12px;
		color: #999999;
		line-height: 25px;
		float: left;
		margin-right: 10px;
	}
	.subjectIndex .indexColor{
		color: #ff771e;
	}
	.subjectTitle{
		font-size: 14px;
		color: #101e18;
		line-height: 25px;
		float: left;
	}
	.optionList{
		padding: 21px 25px 25px;
		border-bottom: 1px solid #e2e2e2;
		position: relative;
	}
	.multipleItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.multipleItem .circular{
		border-radius: 3px;
	}
	.multipleItem .csolid{
		border-radius: 2px;
	}
	.multipleItem.cuurChose{
		color: #5ec513;
	}
	.multipleItem.cuurChose .circular{
		border: 1px solid #5ec513;
	}
	.multipleItem.cuurChose .csolid{
		background-color: #5ec513;
	}
	.optionItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.optionItem::after,
	.answerList::after{
		content: "";
		clear: both;
		display: block;
	}
	.circular{
		width: 16px;
		height: 16px;
		border-radius: 8px;
		border: 1px solid #E2E2E2;
		float: left;
		margin-right: 10px;
		text-align: center;
		line-height: 16px;
	}
	.csolid{
		width: 12px;
		height: 12px;
		border-radius: 6px;
		display: inline-block;
	}
	.optionItem.correct,
	.multipleItem.correctM{
		color: #5EC513;
	}
	.optionItem.correct .circular,
	.multipleItem.correctM .circular{
		border: 1px solid #5EC513;
	}
	.optionItem.correct .csolid,
	.multipleItem.correctM .csolid{
		background-color: #5EC513;
	}
	.optionItem.error,
	.multipleItem.errorM{
		color: #EA3410;
	}
	.optionItem.error .circular,
	.multipleItem.errorM .circular{
		border: 1px solid #EA3410;
	}
	.optionItem.error .csolid,
	.multipleItem.errorM .csolid{
		background-color: #EA3410;
	}
</style>
